<template>
	<div class="traveller" :class="{ expanded }">
		<div class="picture-wrapper">
			<div class="picture">
				<div v-if="profilePicture" class="image">Profile Pic Here</div>
				<div v-else class="initials">{{ initials }}</div>
			</div>
			<span class="type" :class="type" v-if="type"></span>
		</div>
		
		<div class="name">
			<div class="guest">
				<div v-if="isGuest">
					Guest
					<img src="@/assets/travellers/alert-icon.png" alt="Guest Icon" />
				</div>
			</div>
			<div class="firstname">{{ this.firstName }}</div>
			<div class="lastname">{{ this.lastName }}</div>
		</div>

		<div class="right-section">
			<div v-if="hasToggle" class="toggle-wrapper" >
				<div class="loader" v-if="isLoading">
					<svg version="1.1" id="L1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
						<circle fill="none" stroke="#06D6A0" stroke-width="1" stroke-miterlimit="15" stroke-dasharray="14.2472,14.2472" cx="50" cy="50" r="47">
							<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="5s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
						</circle>
						<circle fill="none" stroke="#06D6A0" stroke-width="1" stroke-miterlimit="10" stroke-dasharray="10,10" cx="50" cy="50" r="39">
							<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="5s" from="0 50 50" to="-360 50 50" repeatCount="indefinite"></animateTransform>
						</circle>
						<g class="ldl-scale" style="transform-origin: 38% 50%; transform: rotate(0deg) scale(0.2, 0.2);"><g class="ldl-ani" style="transform-origin: 108.51px 52.97px; transform: rotate(0deg); animation: 1.40845s linear 0s infinite normal forwards running spin-d3463830-e27c-4de4-b8ed-75bbfc200149;"><g class="ldl-layer"><g class="ldl-ani"><g id="Layer_1-2"><g class="ldl-layer"><g class="ldl-ani"><path d="M164.05,0c-15.24,0-28.97,6.43-38.63,16.73l-16.1,16.51,.06,.06-36.81,37.75-1.08,1.11-.06-.06c-4.78,4.62-11.29,7.46-18.47,7.46-14.68,0-26.59-11.9-26.59-26.59s11.9-26.59,26.59-26.59c6.13,0,11.77,2.07,16.27,5.56l-8.23,8.5h.39l37.98,.76-1.37-18.99-1.34-18.58-9.02,9.31C78.36,4.88,66.23,0,52.97,0,23.72,0,0,23.72,0,52.97s23.72,52.97,52.97,52.97c15.02,0,28.57-6.25,38.21-16.29l-.04-.04,.23-.24,53.1-54.46,.35-.36,.03,.03c4.84-5.06,11.66-8.2,19.21-8.2,14.68,0,26.59,11.9,26.59,26.59s-11.9,26.59-26.59,26.59c-7.3,0-13.91-2.94-18.71-7.7h0l-16.17-15.76-18.24,18.7,12.62,12.3c9.72,11.52,24.26,18.83,40.5,18.83,29.25,0,52.97-23.72,52.97-52.97S193.31,0,164.05,0Z" class="cls-1" style="fill: #06D6A0;"></path></g></g></g></g></g></g></g>
					</svg>
				</div>
				<label class="toggle" :class="{ 'loading': isLoading }" :for="travellerID">
					<input type="checkbox" v-model="isToggled" @change="toggleTraveller()" value="true" :id="travellerID">
					<span class="round-slider"></span>
				</label>
			</div>

			<div class="edit icon" 
				v-if="canEdit"
				@click="() => { expanded = !expanded }"
			>
				<img src="@/assets/travellers/edit-icon.png" alt="Edit Icon" />
			</div>
			<div class="view icon" 
				v-else-if="canView"
				@click="() => { expanded = !expanded }"
			>
				<img src="@/assets/travellers/view-icon.png" alt="Edit Icon" />
			</div>
		</div>
		<div class="clear"></div>
		<div class="validation" v-if="validationErrors && Object.keys(validationErrors).reduce((acc, key) => acc.concat(validationErrors[key]), []).length > 0">
			<div class="notification-action blue">
				<div class="title">Traveller details issue</div>
				<div class="text">There is an issue with this traveller details.</div>
				<div class="errors">
					<div v-for="(errors, key) in validationErrors" :key="key">
						<div class="error" v-for="error in errors" :key="error">
							<img class="alert-icon" width="14px" src="@/assets/travellers/yellow-alert-icon.png" alt="Alert Icon" />
							{{ error }}
						</div>
					</div>
				</div>
				<button class="button edit" @click="editTraveller()">Edit Details</button>
			</div>
		</div>
		<div>
			<div class="related-adult" v-if="isToggled && type == 'Infant' && this.tripItemID != null">
				<div class="loader" v-if="loading">
					<svg version="1.1" id="L1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
						<circle fill="none" stroke="#06D6A0" stroke-width="1" stroke-miterlimit="15" stroke-dasharray="14.2472,14.2472" cx="50" cy="50" r="47">
							<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="5s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
						</circle>
						<circle fill="none" stroke="#06D6A0" stroke-width="1" stroke-miterlimit="10" stroke-dasharray="10,10" cx="50" cy="50" r="39">
							<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="5s" from="0 50 50" to="-360 50 50" repeatCount="indefinite"></animateTransform>
						</circle>
						<g class="ldl-scale" style="transform-origin: 38% 50%; transform: rotate(0deg) scale(0.2, 0.2);"><g class="ldl-ani" style="transform-origin: 108.51px 52.97px; transform: rotate(0deg); animation: 1.40845s linear 0s infinite normal forwards running spin-d3463830-e27c-4de4-b8ed-75bbfc200149;"><g class="ldl-layer"><g class="ldl-ani"><g id="Layer_1-2"><g class="ldl-layer"><g class="ldl-ani"><path d="M164.05,0c-15.24,0-28.97,6.43-38.63,16.73l-16.1,16.51,.06,.06-36.81,37.75-1.08,1.11-.06-.06c-4.78,4.62-11.29,7.46-18.47,7.46-14.68,0-26.59-11.9-26.59-26.59s11.9-26.59,26.59-26.59c6.13,0,11.77,2.07,16.27,5.56l-8.23,8.5h.39l37.98,.76-1.37-18.99-1.34-18.58-9.02,9.31C78.36,4.88,66.23,0,52.97,0,23.72,0,0,23.72,0,52.97s23.72,52.97,52.97,52.97c15.02,0,28.57-6.25,38.21-16.29l-.04-.04,.23-.24,53.1-54.46,.35-.36,.03,.03c4.84-5.06,11.66-8.2,19.21-8.2,14.68,0,26.59,11.9,26.59,26.59s-11.9,26.59-26.59,26.59c-7.3,0-13.91-2.94-18.71-7.7h0l-16.17-15.76-18.24,18.7,12.62,12.3c9.72,11.52,24.26,18.83,40.5,18.83,29.25,0,52.97-23.72,52.97-52.97S193.31,0,164.05,0Z" class="cls-1" style="fill: #06D6A0;"></path></g></g></g></g></g></g></g>
					</svg>
				</div>
				<div :class="{ 'loading': loading }">
					<span v-if="showRelatedAdults && relatedTravellerOptions.length > 0">Please select the adult responsible for the infant</span>
					<span v-else-if="showRelatedAdults && relatedTravellerOptions.length == 0">Please add an adult to accompany the infant</span>
					<span v-else>The adult responsible for the infant</span>
					<div v-for="adult in relatedTravellerOptions" :key="adult.traveller_id">

						<div class="option" :class="{ selected: adult.traveller_id == relatedTravellerID }" v-if="showRelatedAdults">
							{{ adult.data.firstName }} {{ adult.data.lastName }}
							<div class="selector" @click="selectRelatedAdult(adult.traveller_id)" v-if="adult.traveller_id != relatedTravellerID">
								<img class="select-icon" src="@/assets/travellers/adult-unselected.png">
							</div>
							<div class="selector selected" v-else @click="showRelatedAdults = false">
								<img class="select-icon" src="@/assets/travellers/adult-selected.png">
							</div>
						</div>

						<div class="option grey" v-if="!showRelatedAdults && adult.traveller_id == relatedTravellerID">
							{{ adult.data.firstName }} {{ adult.data.lastName }}
							<div class="selector" @click="showRelatedAdultOptions()">
								<img class="select-icon" src="@/assets/travellers/adult-reselect.png">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Transition name="expand">
			<div v-if="expanded" class="expandable-wrapper">
				<div class="loader" v-if="loading">
					<svg version="1.1" id="L1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
						<circle fill="none" stroke="#06D6A0" stroke-width="1" stroke-miterlimit="15" stroke-dasharray="14.2472,14.2472" cx="50" cy="50" r="47">
							<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="5s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
						</circle>
						<circle fill="none" stroke="#06D6A0" stroke-width="1" stroke-miterlimit="10" stroke-dasharray="10,10" cx="50" cy="50" r="39">
							<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="5s" from="0 50 50" to="-360 50 50" repeatCount="indefinite"></animateTransform>
						</circle>
						<g class="ldl-scale" style="transform-origin: 38% 50%; transform: rotate(0deg) scale(0.2, 0.2);"><g class="ldl-ani" style="transform-origin: 108.51px 52.97px; transform: rotate(0deg); animation: 1.40845s linear 0s infinite normal forwards running spin-d3463830-e27c-4de4-b8ed-75bbfc200149;"><g class="ldl-layer"><g class="ldl-ani"><g id="Layer_1-2"><g class="ldl-layer"><g class="ldl-ani"><path d="M164.05,0c-15.24,0-28.97,6.43-38.63,16.73l-16.1,16.51,.06,.06-36.81,37.75-1.08,1.11-.06-.06c-4.78,4.62-11.29,7.46-18.47,7.46-14.68,0-26.59-11.9-26.59-26.59s11.9-26.59,26.59-26.59c6.13,0,11.77,2.07,16.27,5.56l-8.23,8.5h.39l37.98,.76-1.37-18.99-1.34-18.58-9.02,9.31C78.36,4.88,66.23,0,52.97,0,23.72,0,0,23.72,0,52.97s23.72,52.97,52.97,52.97c15.02,0,28.57-6.25,38.21-16.29l-.04-.04,.23-.24,53.1-54.46,.35-.36,.03,.03c4.84-5.06,11.66-8.2,19.21-8.2,14.68,0,26.59,11.9,26.59,26.59s-11.9,26.59-26.59,26.59c-7.3,0-13.91-2.94-18.71-7.7h0l-16.17-15.76-18.24,18.7,12.62,12.3c9.72,11.52,24.26,18.83,40.5,18.83,29.25,0,52.97-23.72,52.97-52.97S193.31,0,164.05,0Z" class="cls-1" style="fill: #06D6A0;"></path></g></g></g></g></g></g></g>
					</svg>
				</div>
				<div :class="{ 'loading': loading }">
					<div class="expandable">
						<img v-if="canEdit" @click="editTraveller()" src="@/assets/travellers/edit-pen.png" class="edit-pen" alt="Edit Icon" />
						<div class="fullname">{{ firstName }} {{ lastName }}</div>
						<div class="other">DOB: {{ formatDate(dob) }}</div>
						<div class="other">{{ passportNumber }}</div>
						<div class="other">Exp. {{ formatDate(passportExpiry) }}</div>
					</div>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
// What happens if we edit a guest (are there trip versions, or just one version of details seeing as it's not linked to a separate user)
// -- What then, if the guest is on two separate trips, and another user edits the guests details for 1 trip (and shouldn't change on the other trip?)

import router from '@/router';
import helpers from '@/helpers/helpers.js';
import { mapActions } from 'vuex';
import track from '@/helpers/track.js';

export default {
	props: {
		travellerID: {
			type: Number,
		},
		userID: {
			type: Number,
			default: null,
		},
		firstName: {
			type: String,
		},
		lastName: {
			type: String,
		},
		dob: {
			type: String,
		},
		passportNumber: {
			type: String,
		},
		passportExpiry: {
			type: String,
		},
		nationality: {
			Object,
			default: null
		},
		profilePicture: {
			type: String, // URL?
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		validationErrors: {
			Object,
			default: null,
		},
		relatedTravellerID: {
			type: Number,
			default: null,
		},
		relatedTravellerOptions: {
			type: Array,
			default: null, // Could be an empty array depending on use of component
		},
		toggled: {
			type: Boolean,
			default: false, 
		},
		loading: { // Enables the toggle to be disabled from parent component while something is loading.
			type: Boolean,
			default: false,
		},
		tripID: {
			type: Number,
			default: null,
		},
		tripItemID: {
			type: Number,
			default: null,
		},
	},
	watch: { 
		relatedTravellerOptions: function() { // watch it
			// Must determine if the related adult options show based on the given prop of options.
			this.determineShouldRelatedAdultOptionsShow();
		},
	},
	data() {
		return {
			expanded: (this.validationErrors && Object.keys(this.validationErrors).reduce((acc, key) => acc.concat(this.validationErrors[key]), []).length > 0) ? true : false, // If there are validation errors, toggle by default,
			isToggled: this.toggled,
			isLoading: false, // Local to component, default to false
			// relatedTraveller: this.relatedTravellerID,
			showRelatedAdults: this.relatedTravellerID ? false : true,
		}
	},
	computed: {
		initials() {
			return this.firstName.charAt(0) + this.lastName.charAt(0);
		},

		hasToggle() {
			return this.tripID || this.tripItemID; // has toggle if tripID or tripItemID is provided to component
		},
		canEdit() {
			return this.tripID || this.tripItemID || this.isGuest; // Can be edited if the traveller is on a trip, or there is no userID (is guest)
		},
		canView() {
			return !this.canEdit; // Can view if can not be edited
		},
		isGuest() {
			return this.userID == null;
		},
		
	},
	methods: {
		...mapActions({
			addExistingTravellerToTrip: "trip/addExistingTravellerToTrip",
			removeTravellerFromTrip: "trip/removeTravellerFromTrip",
			addRelatedTravellerToInfant: "trip/addRelatedTravellerToInfant",
			alertError: "alert/error",
		}),
		formatDate: (date) => helpers.formatDate(date),
		toggleTraveller() {
			if(this.isToggled == true) { // Has been toggled to true
				this.isLoading = true;
				// Track event
				try {
					track.event('addTraveller', {
						travellerAddType: 'existing traveller',
						tripID: this.tripID,
						tripItemID: this.tripItemID ? this.tripItemID : "",
						travellerID: this.travellerID,
						travellerNationality: this.nationality.code,
					});
				} catch(e) {
					console.error(e);
					this.$rollbar.error("Tracking: " + e);
				}
				
				this.addExistingTravellerToTrip({ paxID: this.travellerID, tripID: this.tripID, tripItemID: this.tripItemID } )
					.then(
						response => {
							this.determineShouldRelatedAdultOptionsShow();
							this.$emit('update');
							this.isLoading = false;
						},
						error => {
							this.isToggled = false; // If it fails - revert the toggled value
							this.alertError('Something went wrong');
							this.isLoading = false;
						}
					);
			} else if(this.isToggled == false) { // will change to false
				this.isLoading = true;
				// Track event
				try {
					track.event('removeTraveller', {
						tripID: this.tripID,
						tripItemID: this.tripItemID ? this.tripItemID : "",
						travellerID: this.travellerID,
						travellerNationality: this.nationality.code,
					});
				} catch(e) {
					console.error(e);
					this.$rollbar.error("Tracking: " + e);
				}
				
				this.removeTravellerFromTrip({ paxID: this.travellerID, tripID: this.tripID, tripItemID: this.tripItemID } )
					.then(
						response => {
							// Remove option from related travellers // TODO: this could replace the update emit???
							// this.relatedTravellerOptions
							this.determineShouldRelatedAdultOptionsShow();
							this.$emit('update');
							this.isLoading = false;
						},
						error => {
							this.isToggled = true; // If it fails - revert the toggled value
							this.alertError('Something went wrong');
							this.isLoading = false;
						}
					);
			}
		},
		editTraveller() {
			if(this.canEdit) { // Includes trip item ID by default
				// Go to edit traveller page (to update the TRIP LEVEL details)
				router.push({ 
					name: "Edit Traveller", 
					params: {
						traveller_id: this.travellerID,
						trip_id: this.tripID,
						trip_item_id: this.tripItemID,
					},
					query: {
						redirect: this.$route.fullPath,
					}
				})
			} else {
				// We should not be able to edit travellers if they are not on a trip level. Should not show any button that would allow this to be reached.
			}
		},
		showRelatedAdultOptions() {
			this.showRelatedAdults = true;
		},
		selectRelatedAdult(selectedTravellerID) {
			if(this.type == "Infant" && this.tripItemID != null) {
				this.addRelatedTravellerToInfant({ travellerID: this.travellerID, tripID: this.tripID, tripItemID: this.tripItemID, relatedTravellerID: selectedTravellerID })
					.then(response => {
						this.isLoading = false;
						// this.relatedTraveller = selectedTravellerID;
						this.showRelatedAdults = false;
						this.$emit('update');
					})
					.catch(error => {
						this.$rollbar.error(error);
						this.isLoading = false;
						this.alertError('Something went wrong');
					});
			}
		},
		determineShouldRelatedAdultOptionsShow() { 	// Determines if "select a responsible adult" list should be showing 
			this.showRelatedAdults = this.relatedTravellerID ? false : true; // Don't show if there is one already selected
			// BUT if the current selected adult is not a valid option (pax is not on this trip) then show options. This can happen if they are removed from the trip after being selected for an infant.
			if(this.relatedTravellerOptions != null && this.relatedTravellerOptions.filter(e => e.traveller_id == this.relatedTravellerID).length == 0) {
				this.showRelatedAdults = true;
			}
		}
	},
	created() {
		this.determineShouldRelatedAdultOptionsShow();
	},
	components: {
	}
}
</script>

<style scoped>
	.traveller {
		border: 1px solid #E5E5E5;
		border-radius: 10px;
		width: 350px;
		max-width: 100%;
		min-height: 80px;
		text-align: left;
		position: relative;
	}
	.traveller.expanded {
		border-color: #118AB2;
	}
	.traveller .picture {
		width: 62px;
		height: 62px;
		border-radius: 31px;
		overflow: hidden;
		margin-top: 10px;
		margin-left: 13px;
		display: inline-block;
	}
	.traveller .picture .initials {
		text-transform: uppercase;
		background-color: #118AB2;
		width: 100%;
		height: 100%;
		text-align: center;
		line-height: 62px;
		color: white;
		font-size: 32px;
		font-weight: 700;
	}
	.traveller .picture-wrapper {
		display: inline-block;
		position: relative;
	}
	.traveller .type {
		display: inline-block;
		width: 29px;
		height: 29px;
		background-size: 29px;
		position: absolute;
		right: -11px;
		bottom: 3px;
	}
	.traveller .type.Adult {
		background-image: url("../../assets/travellers/adult-icon.png");
	}
	.traveller .type.Child {
		background-image: url("../../assets/travellers/child-icon.png");
	}
	.traveller .type.Infant {
		background-image: url("../../assets/travellers/infant-icon.png");
	}
	/* .traveller .type.Invalid {
		background-image: url("../../assets/travellers/red-tick.png");
		background-size: 12px;
	} */

	.traveller .name {
		display: inline-block;
		vertical-align: top;
		margin-top: 15px;
		margin-bottom: 15px;
		margin-left: 17px;
		max-width: calc(100% - 195px);
		min-width: 100px;
		box-sizing: border-box;
		overflow-wrap: break-word;
	}
	.traveller .name .guest {
		font-size: 10px;
		height: 15px;
		color: #50555C;
	}
	.traveller .name .guest img {
		width: 14px;
		vertical-align: middle;
	}
	.traveller .name .lastname {
		font-size: 12px;
		margin-top: 3px;
	}

	.traveller .right-section {
		float: right;
		vertical-align: middle;
		margin-top: 23px;
		margin-right: 15px;
	}
	.traveller .icon {
		display: inline-block;
		cursor: pointer;
		vertical-align: middle;
	}
	.traveller .icon img {
		width: 38px;
	}

	.traveller .expandable-wrapper {
		overflow: hidden;
		position: relative;
	}
	.traveller .loader {
		position: absolute;
		width: 120px;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
	}
	.traveller .expandable {
		margin: 15px 15px 23px 15px;
		border: 1px solid #e5e5e5;
		background-color: #FCFCFE;
		border-radius: 10px;
		padding: 21px 14px;
	}
	.traveller .expandable .edit-pen {
		float: right;
		width: 12px;
		margin-top: 7px;
	}
	.traveller .expandable .fullname {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		word-break: break-word;
	}
	.traveller .expandable .other {
		font-size: 14px;
		color: #50555C;
		line-height: 24px;
	}
	
	.traveller .related-adult {
		margin: 15px;
		font-size: 12px;
		color: #50555C;
		line-height: 15px;
		position: relative;
	}
	.traveller .related-adult .option {
		font-weight: 400;
		font-size: 14px;
		line-height: 40px;
		color: black;
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
		height: 40px;
	}
	.traveller .related-adult .option.grey {
		background: #FBFBFB;
	}
	.traveller .related-adult .option .select-icon {
		width: 32px;
		float: right;
		margin-top: 5px;
	}
	.traveller .related-adult .selector {
		color: blue;
		float: right;
		cursor: pointer;
	}
	.traveller .related-adult .selector.selected {
		color: black;
		float: right;
	}

	.traveller .validation {
		margin: 15px 30px;
	}
	.traveller .validation .notification-action .text {
		text-align: left;
	}
	.traveller .validation .title {
		font-size: 18px;
	}
	.traveller .validation .errors {
		text-align: left;
		margin-top: 10px;
	}
	.traveller .validation .error {
		font-size: 12px;
		line-height: 18px;
		color: #000000;
		vertical-align: middle;
	}
	.traveller .validation .error .alert-icon {
		vertical-align: middle;
	}
	.traveller .validation .button {
		margin-top: 15px;
	}
	.toggle-wrapper {
		display: inline-block;
		margin-right: 4px;
		position: relative;
	}
	.loading {
		opacity: 0.3;
		pointer-events: none;
	}
	.toggle-wrapper .loader {
		position: absolute;
		height: 40px;
		width: 40px;
		transform: translateY(-50%);
		top: 50%;
		left: -45px;
	}
	.toggle-wrapper .toggle {
		position: relative;
		display: inline-block;
		width: 40px;
		height: 20px;
	}
	.toggle-wrapper .toggle input {
		opacity: 0;
		width: 0;
		height: 0;
	}
	.round-slider {
		position: absolute;
		cursor: pointer;
		width: 40px;
		border-radius: 15px;
		top: 0;
		bottom: 0;
		right: 0;
		background-color: #979797;
		-webkit-transition: .4s;
		transition: .4s;
	}
	.round-slider:before {
		content: "";
		position: absolute;
		width: 16px;
		height: 16px;
		left: 2px;
		bottom: 2px;
		background-color: #ffffff;
		border-radius: 50%;
		-webkit-transition: .4s;
		transition: all  .4s ease;
	}
	.toggle-wrapper .toggle input:checked + .round-slider {
		background-color: #118AB9;
	}
	.toggle-wrapper .toggle input:checked + .round-slider:before {
		-webkit-transform: translateX(20px);
		-ms-transform: translateX(20px);
		transform: translateX(20px);
	}

</style>